/* Google Fonts */
/* font-family: 'Share Tech', sans-serif; */
*,
*::before,
*::after {
  box-sizing: border-box;
}
page html,
body {
  background-color: #e6e6ff;
  font-family: "Share Tech", sans-serif;
  margin: 0;
}

.page-title {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin: 20px;
}
/* Forms */
.form-container {
  width: 400px;
  margin: auto;
}

.form-container.ui.stackable.container {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
}

/* Home Page */

.ui.form .field.field input:-webkit-autofill {
  /* box-shadow: inset 0 0 0 100px ivory !important;
  border-color: #e5dfa1 !important; */
  box-shadow: 0 0 0 100px white inset !important; /* Change the color to your own background color */
  /* border-color: #dc3a8e !important; */
  -webkit-text-fill-color: #333;
}

h1,
h2 {
  font-family: "Share Tech", sans-serif;
}

.ui.container > .column {
  display: flex;
  justify-content: center;
}

#root > div > div > div.ui.container > div > div.post.row > div {
  display: flex;
  justify-content: center;
}

/* Spinner */

.ui.active.inverted.dimmer {
  margin-top: 20px;
  color: red;
}

/* Cards */
.ui.cards {
  text-align: left;
}

/* Main Page  */
/* #root > div > div > div.form-container > div > div > form{
  background-color: blue !important;
  border: solid red;
}
#root > div > div > div.form-container > div > div > form > div:nth-child(1) > div{
  background-color: yellow !important;
}

#root > div > div > div.form-container > div > div > form > div:nth-child(2){
  background-color: yellowgreen !important;
} */

/* UI Container */

.ui.grid.container {
  margin: auto;
  justify-content: center;
}

#login-btn,
.share-btn {
  width: 100%;
  padding: 10px;
  font-size: large;
  background-color: #654aa8;
  margin-bottom: 10px;
}

/* Img Logo */
.logo {
  height: 300px;
}

/* Register Page */
#register-btn {
  width: 100%;
  padding: 10px;
  font-size: large;
}

.form-container {
  border: red;
}

/* Header */
header {
  padding: 20px;
}
/* Footer */
footer {
  padding: 50px;
}
/* #root > div > div > div.ui.violet.massive.pointing.secondary.menu > a{
  color: purple;
} */
/* #root > div > div > div.ui.teal.massive.pointing.secondary.menu > a{
  background-color: #654AA8 !important;
  color: magenta !important;
} */

.ui.cards {
  max-height: 300px;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 435px) {
  div.ui.container > div > div:nth-child(2) > div > form > div > textarea {
    height: 200px;
  }
  form.ui.form {
    width: 400px;
  }
}
/* @media only screen and (min-width: 400px) and (max-width: 775px) {
  .ui.container > .column,
  #root > div > div > div.ui.container > div > div.post.row > div .page-title {
    display: flex;
    margin-left: 10%;
  } 
} */
